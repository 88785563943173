import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';
import OtherServices from '../components/OtherServices';

const ServiceDetailsTemplate = ({
  data: {
    datoCmsService: { seoMetaTags, title, bannerText, modularBlocks },
    allDatoCmsService: { otherServices },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner heading={title} text={bannerText} />
      <ModularBlocks items={modularBlocks} />
    </main>
    <OtherServices items={otherServices} />
  </Layout>
);

export const ServiceDetailsTemplateQuery = graphql`
  query ServiceDetailsTemplateQuery($id: String!) {
    datoCmsService(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerText
      modularBlocks {
        ...AccordionModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentBlocksModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...FeaturedProjectsModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...HighlightedLogosModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...ImageSliderModularBlockFragment
        ...InternalVideoModularBlockFragment
        ...StatementTextModularBlockFragment
        ...TabsModularBlockFragment
        ...TestimonialModularBlockFragment
      }
    }
    allDatoCmsService(
      filter: { id: { ne: $id } }
      sort: { fields: position, order: ASC }
      limit: 2
    ) {
      otherServices: nodes {
        id
        heading: title
        slug
        text: bannerText
        image: featuredImage {
          gatsbyImageData(width: 710, height: 680)
          alt
        }
      }
    }
  }
`;

export default ServiceDetailsTemplate;
