import React from 'react';
import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  sectionMargins,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
} from '../styles';
import { Container, Heading, Svg, Link } from './ui';
import { buildUrl } from '../utils';
import arrowIcon from '../images/right-arrow.inline.svg';

const StyledOtherServices = styled.section`
  ${sectionMargins()};
`;

const StyledHeader = styled.header`
  margin-bottom: 30px;

  ${minBreakpointQuery.small`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 50px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 60px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-bottom: 70px;
  `}
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${brandColours.primary};
  font-weight: ${fontWeights.medium};
  ${fontSize(18)};

  ${maxBreakpointQuery.smedium`
    margin-top: 12px;
  `}

  ${minBreakpointQuery.small`
    ${fontSize(21)};
  `}

  ${minBreakpointQuery.smedium`
    margin-left: 12px;
  `}

  ${minBreakpointQuery.large`
    ${fontSize(24)};
  `}
`;

const StyledLinkArrow = styled(Svg)`
  margin-left: 12px;
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  fill: ${brandColours.primary};

  ${minBreakpointQuery.small`
    margin-left: 14px;
    height: 18px;
    width: 18px;
    min-height: 18px;
    min-width: 18px;
  `}

  ${minBreakpointQuery.large`
    margin-left: 16px;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
  `}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 15px;

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

const StyledItem = styled.article`
  position: relative;
  cursor: pointer;
`;

const StyledImage = styled(GatsbyImage)`
  max-height: 300px;

  ${minBreakpointQuery.tiny`
    max-height: 380px;
  `}

  ${minBreakpointQuery.small`
    max-height: 460px;
  `}

  ${minBreakpointQuery.medium`
    max-height: 540px;
  `}
`;

const StyledContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px 20px;
  width: 85%;
  background-color: ${standardColours.white};

  ${minBreakpointQuery.tiny`
    padding: 40px 30px;
  `}

  ${minBreakpointQuery.large`
    padding: 50px 40px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding: 60px 50px;
  `}
`;

const StyledHeading = styled.h3`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  color: ${brandColours.primary};
  ${fontSize(21)};

  ${minBreakpointQuery.tiny`
    ${fontSize(24)};
  `}

  ${minBreakpointQuery.small`
    ${fontSize(27)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(30)};
  `}
`;

const StyledHeadingArrow = styled(Svg)`
  margin-left: 10px;
  height: 18px;
  width: 18px;
  min-height: 18px;
  min-width: 18px;
  fill: ${brandColours.primary};

  ${minBreakpointQuery.tiny`
    margin-left: 12px;
    height: 22px;
    width: 22px;
    min-height: 22px;
    min-width: 22px;
  `}

  ${minBreakpointQuery.small`
    margin-left: 14px;
    height: 26px;
    width: 26px;
    min-height: 26px;
    min-width: 26px;
  `}

  ${minBreakpointQuery.large`
    margin-left: 16px;
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
  `}
`;

const StyledText = styled.p`
  margin-top: 12px;
  color: ${brandColours.tertiary};
  ${fontSize(14)};
  line-height: 1.7;

  ${minBreakpointQuery.small`
    margin-top: 14px;
    ${fontSize(16)};
  `}

  ${minBreakpointQuery.large`
    margin-top: 16px;
    ${fontSize(18)};
  `}
`;

const OtherServices = ({ items }) =>
  items.length > 0 && (
    <StyledOtherServices>
      <Container>
        <StyledHeader>
          <Heading>View other Services</Heading>
          <StyledLink to="services">
            View all
            <StyledLinkArrow image={arrowIcon} />
          </StyledLink>
        </StyledHeader>
        <StyledItems>
          {items.map(({ id, heading, slug, text, image }) => {
            const urlPath = `services/${slug}`;

            return (
              <StyledItem key={id} onClick={() => navigate(buildUrl(urlPath))}>
                <StyledImage image={image.gatsbyImageData} alt={image.alt} />
                <StyledContent>
                  <StyledHeading>
                    <Link to={urlPath}>{heading}</Link>
                    <StyledHeadingArrow image={arrowIcon} />
                  </StyledHeading>
                  {text && <StyledText>{text}</StyledText>}
                </StyledContent>
              </StyledItem>
            );
          })}
        </StyledItems>
      </Container>
    </StyledOtherServices>
  );

export default OtherServices;
